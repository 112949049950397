import React from "react";
import { graphql } from "gatsby";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import clsx from "clsx";
import PropTypes from "prop-types";
import Layout from "components/Layout";
import "./Reviews.scss";

export default function Reviews({ data, location }) {
  const [selectedReviewID, setSelectedReviewID] = React.useState(null);
  const pathName = location.pathname.split("/")[1];

  const scrollElement = React.useRef(null);

  const {
    markdownRemark: {
      frontmatter: { reviews },
    },
  } = data;

  const setSelectedReview = (reviewID) => {
    if (selectedReviewID === reviewID) {
      setSelectedReviewID(-1);
      return;
    }

    setSelectedReviewID(reviewID);
    setTimeout(
      () => {
        if (window.innerWidth < 576) {
          scrollElement.current.scrollIntoView({ behavior: "smooth", block: "start" });
        }
      },
      100,
    );
  };

  const isSelected = (reviewID) => {
    return selectedReviewID === reviewID;
  };

  const selectedReview = reviews.find((review) => review.reviewID === selectedReviewID);

  return (
    <Layout pathName={pathName}>
      <Container fluid>
        <Row>
          <Col xs={12} sm={5}>
            <div>
              <table className="table table-striped table-bordered table-hover table-condensed">
                <tbody>
                  {reviews.map((review) => (
                    <tr key={review.reviewID}>
                      <td style={{ verticalAlign: "middle" }}>{review.reviewCaption}</td>
                      <td className="text-center" style={{ verticalAlign: "middle" }}>
                        <Button
                          variant="info"
                          onClick={() => setSelectedReview(review.reviewID)}
                          className={clsx(
                            { selected: isSelected(review.reviewID) },
                            "btn-custom btn-info-custom",
                          )}
                        >
                          Read More
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </Col>

          <Col xs={12} sm={7} ref={scrollElement}>
            {selectedReview && (
              <Card border="primary" className="mb-4">
                <Card.Header className="card-header-custom">
                  <h3 className="card-title-custom">{selectedReview.serviceName} </h3>
                </Card.Header>

                <Card.Body>
                  <div dangerouslySetInnerHTML={{ __html: selectedReview.reviewContent }} />
                </Card.Body>

                <Card.Footer>
                  <b>Reviewed By:</b> {selectedReview.reviewBy}
                </Card.Footer>
              </Card>
            )}
          </Col>

          <div className="clearfix" />

          <Col xs={12} sm={4}>
            <a
              href="http://my.angieslist.com/angieslist/review/5951881"
              target="_blank"
              rel="noreferrer"
            >
              Review Roseville Pool Service on Angieslist
            </a>
            <br />
            <br />
            <a
              href="http://yelp.com/biz/roseville-pool-service-roseville?"
              target="_blank"
              rel="noreferrer"
            >
              Check out Roseville Pool Service Reviews on Yelp
            </a>
            <br />
            <br />
            <a
              href="https://search.google.com/local/writereview?placeid=ChIJ5yOkGgggm4ARxFeIxUqS4Ho"
              target="_blank"
              rel="noreferrer"
            >
              Review Roseville Pool Service on Google
            </a>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}

Reviews.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
};

Reviews.defaultProps = {
  data: null,
  location: null,
};

export const query = graphql`
  query {
    markdownRemark(fields: { slug: { eq: "/pages/4-Reviews/" } }) {
      html
      frontmatter {
        reviews {
          reviewID
          serviceName
          reviewBy
          reviewCaption
          reviewContent
        }
      }
    }
  }
`;
